.dashbaord-view-scroll{
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 127px);
    .dashboard-header{
        padding: 32px;
        border: 1px solid var(--col-br-dark);
        border-radius: 8px;
        background-color: rgba(243, 252, 255, 0.6);
        .title{
            font-size: var(--font-24);
            font-weight: var(--f-w-600);
            line-height: 32px;
            letter-spacing: 0.01em;
        }
        .row{
            margin-left: -12px !important;
            margin-right: -12px !important;
            .col-md-4.custom-col{
                padding-left: 12px !important;
                padding-right: 12px !important;
                .box-container{
                    border-radius: 8px;
                    border: 1px solid var(--col-br-dark);
                    gap: 20.5px;
                    .box-title{
                        gap: 9px;
                        height: 32px;
                        font-size: var(--font-14);
                        font-weight: var(--f-w-400);
                        line-height: 24px;
                        letter-spacing: 0em;
                        color: var(--col-secondary);
                    }
                    .box-num-count{
                        height: 32px;
                        h4{
                            font-size: var(--font-32);
                            font-weight: var(--f-w-500);
                            line-height: 40px;
                            letter-spacing: -0.005em;
                            color: var(--col-primary);
                        }
                        select{
                            height: 32px;
                            background-color: var(--col-E4FBFA);
                            color: var(--col-secondary);
                            border-radius: 4px;
                            padding: 0 8px;
                            border: none;
                            font-size: var(--font-13);
                            font-weight: var(--f-w-500);
                            line-height: 15px;
                            letter-spacing: 0.01em;
                            &:focus{
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .dashboard-main-content{
        .row{
            margin-left: -12px !important;
            margin-right: -12px !important;
            .col-md-6.custom-col{
                padding-left: 12px !important;
                padding-right: 12px !important;
                
                .card-container{
                    gap: 24px;
                    border: 1px solid var(--col-br-dark);
                    border-radius: 8px;
                    .card-title-area{
                        h5{
                            font-size: var(--font-16);
                            font-weight: var(--f-w-500);
                            line-height: 24px;
                            letter-spacing: 0.01em;
                            color: var(--col-primary);
                            margin-bottom: 0px;
                        }
                    }
                    .content-set-area{
                        gap:8px;
                        .content-set{
                            height: 50px;
                            h5{
                                font-size: var(--font-14);
                                font-weight: var(--f-w-500);
                                line-height: 1;
                                letter-spacing: 0em;
                                color: var(--col-primary);
                            }
                            span{
                                font-size: var(--font-13);
                                font-weight: var(--f-w-400);
                                line-height: 1;
                                letter-spacing: 0.01em;
                                color: var(--col-body);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991.8px) {
    .dashboard-header{
        padding: 20px;
        .row{
            margin-left: -4px !important;
            margin-right: -4px !important;
            .col-md-4.custom-col{
                padding-left: 4px !important;
                padding-right: 4px !important;
            }
        }
    }
    .dashboard-main-content{
        .row{
            margin-left: -4px !important;
            margin-right: -4px !important;
            .col-md-6.custom-col{
                padding-left: 4px !important;
                padding-right: 4px !important;
            }
        }
    }
}
