.doc-request-box{
    border: 1px solid var(--col-label);
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 32px;
    form{
        height: calc(100vh - 310px);
        overflow-x: hidden;
        overflow-y: auto;
    }
    h5{
        font-size: var(--font-13);
        font-weight: var(--f-w-400);
        line-height: 1;
        letter-spacing: 0.01em;
        color: var(--col-secondary);
        margin-bottom: 8px;
    }
    
    .pills-selected-area{
        gap: 8px;
        .pills-selected{
            height: 28px;
            padding: 0 12px;
            border: 1px solid var(--col-btn-primary);
            font-size: var(--font-11);
            font-weight: var(--f-w-500);
            line-height: 1;
            letter-spacing: 0.01em;
            background-color: var(--col-E4FBFA);
            border-radius: 8888px;
            img{
                cursor: pointer;
            }
        }
    }
    .form-control{
        font-size: var(--font-13);
        font-weight: var(--f-w-500);
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--col-secondary);
        &::placeholder{
            color: var(--col-C5D2E7);
        }
        &:focus{
            box-shadow: none;
        }
    }
    .ssc-primary-green-btn.upload-file-btn{
        width: auto !important;
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--col-white);
        height: 36px;
    }
    .ssc-primary-green-btn.add-upload-file-btn{
        width: auto !important;
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 16px;
        letter-spacing: 0.01em;
        color: var(--col-white);
        border-radius: 100%;
        max-height: 40px;
        max-width: 40px;
        min-height: 40px;
        min-width: 40px;
        padding: 0px;
    }
}
.outter-box-btns{
    gap: 8px;
    .ssc-secondary-white-btn.cancel-btn{
        height: 40px;
        border-radius:  4px !important;
        font-size: var(--font-16) !important;
        font-weight: var(--f-w-600) !important;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
    .ssc-primary-green-btn.submit-btn{
        width: auto !important;
        height: 40px;
        border-radius:  4px !important;
        font-size: var(--font-16) !important;
        font-weight: var(--f-w-600) !important;
        line-height: 24px;
        letter-spacing: 0.01em;
    }
}
.custom-modal-doc-req{
    .modal-dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
        .modal-content{
            border-radius: 16px;
        }
        .modal-body h5{
            margin-bottom: 49px;
            font-size: var(--font-32);
            font-weight: var(--f-w-500);
            line-height: 42px;
            letter-spacing: -0.005em;
        }
        .modal-btn{
            gap: 10px;
            .ssc-primary-green-btn{
                font-size: var(--font-16);
                font-weight: var(--f-w-600);
                line-height: 24px;
                letter-spacing: 0.01em; 
                height: 40px;
                border-radius: 4px;
            }
            .ssc-secondary-white-btn{
                font-size: var(--font-16);
                font-weight: var(--f-w-600);
                line-height: 24px;
                letter-spacing: 0.01em; 
                height: 40px;
                border-radius: 4px;
            }
        }
    }
}
.customer-from-content{
    margin-top: 32px;
        label{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 19.5px;
            letter-spacing: 0.01em;
            color: var(--col-secondary);
        }
        .form-control.input-area-set{
            height: 48px;
            padding: 0 16px;
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 19.5px;
            letter-spacing: 0.005em;
            color: var(--col-primary);
            &:focus{
                border: 1px solid var(--col-btn-primary);
                box-shadow: none !important;
            }
        }
    .wrapper {
        .content {
            .options {
                margin-top: 16px;
                padding: 0;
                max-height: 70px;
                overflow-y: auto;
            }
        }
    }
}
.pill-area-content-modal{
    gap: 8px;
    .pills-tab-area{
        gap: 4px;
        height: 28px;
        padding: 0 12px;
        border: 1px solid var(--col-btn-primary);
        font-size: var(--font-11);
        font-weight: var(--f-w-500);
        line-height: 1;
        letter-spacing: 0.01em;
        background-color: var(--col-E4FBFA);
        border-radius: 8888px;
    }
}
@media (min-width: 991.8px){
    .modal-dialog.custom-wid {
        max-width: 740px;
    }
}
@media (min-width: 767.8px){
    .modal-dialog.custom-wid {
        max-width: 600px;
    }
}
    
