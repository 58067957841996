.ssc-login{
    .scc-login-right{
        background-color: var(--col-label);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }
    .scc-login-left{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        .extra-spacing-rl{
            padding-left: 16px;
            padding-right: 16px;
        }
        // admin-form
        .admin-form{
            border: 1px solid var(--col-br-dark);
            padding: 32px;
            border-radius: 16px;
            gap: 48px;
            .admin-login-btn{
                border: 1px solid var(--col-br-light);
                padding: 12px 74px;
                gap: 20px;
                color: var(--col-4B5564);
                font-size: var(--font-16);
                font-weight: var(--f-w-500);
                line-height: 19px;
                letter-spacing: 0em;
                border-radius: 166px;
            }
        }
        // customer form--> login and create account
        .cust-form{
            border: 1px solid var(--col-br-dark);
            padding: 32px 16px;
            border-radius: 16px;
            h6.form-title{
                font-family: Inter;
                font-size: var(--font-16);
                font-weight: var(--f-w-500);
                line-height: 13px;
                letter-spacing: 0em;
                color: var(--col-4B5564);
                margin-bottom: 40px;
            }
            
            .forget-link{
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 15px;
                letter-spacing: 0.01em;
                text-decoration: none;
                color: var(--col-primary);
            }
            .form-bottom{
                gap: 25px;
                .link{
                    font-size: var(--font-16);
                    font-weight: var(--f-w-500);
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    text-decoration: none;
                    color: var(--col-btn-primary);
                }
            }
        }
        // forget form
        .forget-form{
            border: 1px solid var(--col-br-dark);
            padding: 32px 16px;
            border-radius: 16px;
            .forget-title-bar{
                margin-bottom: 40px;
                h3{
                    font-size: var(--font-24);
                    font-weight: var(--f-w-600);
                    line-height: 32px;
                    letter-spacing: 0.01em;
                    color: var(--col-primary);
                }
                h5{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: var(--col-4B5564);
                }
            }
            .back-btn{
                a{
                    gap: 13px;
                    font-size: var(--font-13);
                    font-weight: var(--f-w-500);
                    line-height: 15px;
                    letter-spacing: 0.01em;
                    text-decoration: none;
                    color: var(--col-primary);
                    p{
                        margin-bottom: 2px;
                    }
                }
            }
        }

        // inputset css starts
        .user-input-section{
            margin-bottom: 40px;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 374px;
            padding-left: 16px;
            padding-right: 16px;
            .input-set{
               &.input-set-gap{
                margin-bottom: 24px;
               }
                label{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 20px;
                    letter-spacing: 0.01em;
                    margin-bottom: 8px;
                    color: var(--col-secondary);
                }
                input{
                    border: 1px solid var(--col-br-dark);
                    border-radius: 8px;
                    padding: 12px 16px;
                    font-size: var(--font-13);
                    font-weight: var(--f-w-500);
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: var(--col-primary);
                    &::placeholder{
                        font-size: var(--font-13);
                        font-weight: var(--f-w-500);
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: var(--col-C5D2E7);
                    }
                    &:focus{
                       box-shadow: none;
                       border: 1px solid var(--col-btn-primary);
                    }
                }
                .eye-icon{
                    top: 15px;
                    right: 16px;
                    background-color: transparent;
                }
            }
            .new-pwd-indicator{
                .indicator-dot{
                    height: 8px;
                    width: 8px;
                    background-color: var(--col-label);
                    margin-right: 10px;
                    border-radius: 100%;
                    &.dot-checked{
                        background-color: var(--col-btn-primary) !important;
                    }
                }
                font-size: var(--font-12);
                font-weight: var(--f-w-400);
                line-height: 1;
                letter-spacing: -0.005em;
                color: var(--col-body);
                .line-set{
                    margin-bottom: 12px;
                }
            }
        }
        // input-set css end 
    }
}

@media screen and (max-width:1199.8px){
    .scc-login-left{
        .admin-form, .cust-form, .forget-form{
            padding: 16px !important;
        }
    }
}
@media screen and (max-width:991.8px){
    .scc-login-left{
        .admin-form, .cust-form, .forget-form{
            padding: 8px  !important;
        }
    }
}
@media screen and (max-width: 676.8px){
    .scc-login-right{
        display: none;
    }
    
}
.form-footer {
    gap: 8px;
    margin-top: 20px;
    a.ssc-footer-link{
        text-decoration: none;
        font-size: var(--font-14);
        line-height: 1;
        color: var(--col-primary);
    }
    h5{
        margin-top: 5px;
    }
}
