#MenuSidebarMobileView4{
    border-radius: 16px;
    position: fixed;
    right: 30px;
    top: 60px;
    border: 1px solid var(--col-br-dark);
    z-index: 100;
    height: auto !important;
    min-width: 360px;
    max-width: 360px;
    transform: translateX(390px);
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    &.show {
        transform: translateX(0px);
    }
    .notification-list{
        overflow: auto;
        max-height: calc(100vh - 250px);
        .notification-text.bar{
            border-bottom: 1px solid var(--col-br-light);
        }
    }
    .all-notification-page-btn{
        text-decoration: none;
        color: var(--col-btn-primary);
        font-size: var(--font-13);
        font-weight: var(--f-w-500);
        line-height: 15px;
        letter-spacing: 0.01em;
        width: 82px;
    }
}