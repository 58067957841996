.notification-title{
    border-bottom: 1px solid var(--col-br-light);
    h5{
        font-size: var(--font-18);
        font-weight: var(--f-w-500);
        line-height: 24px;
        letter-spacing: 0.01em;
        color: var(--col-primary);
        margin-right: 8px;
    }
    span{
        font-size: var(--font-10);
        font-weight: var(--f-w-500);
        line-height: 12px;
        letter-spacing: 0.04em;
        color: var(--col-4B5564);
        padding: 0 8px;
        border-radius: 1666px;
        border: 1px solid var(--col-FF5A4F);
        background-color: var(--col-FFCCDB);
        height: 20px;
        white-space: nowrap;
    }
    .link-text{
        color: var(--col-btn-primary);
        font-size: var(--font-13);
        font-weight: var(--f-w-500);
        line-height: 15px;
        letter-spacing: 0.01em;
        cursor: pointer;
    }
    .tabs-filters{
        height: 58px;
        margin-bottom: -16px;
        .tab-btn{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--col-primary);
            padding: 0 16px;
            height: 58px;
            &.active{
                color: var(--col-btn-primary);
                &::before{
                    position: absolute;
                    content: "";
                    bottom: -1px;
                    left: 0px;
                    height: 1px;
                    width: 100%;
                    background-color: var(--col-btn-primary);
                }
            }
            &:hover{
                color: var(--col-btn-primary);
            }
        }
        .filter-btn{
            border: 1px solid var(--col-E9E9E9);
            padding: 0 16px;
            height: 36px;
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--col-secondary);
            border-radius: 4px;
            margin-left: 77px;
        }
    }
}

.notification-list{
    overflow: auto;
    height: calc(100vh - 210px);
    .notification-text{
        cursor: pointer;
        &.new-notifitication{
            background-color: var(--col-E4FBFA);
        }
        &.page-text{
            margin-bottom: 8px;
            &:last-child{
                margin-bottom: 0px;
            }
        }
        .title{
            font-size: var(--font-16);
            font-weight: var(--f-w-500);
            line-height: 1;
            letter-spacing: 0.01em;
            color: var();
            margin-bottom: 12px;
        }
        .text{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 16px;
            letter-spacing: 0.005em;
            color: var(--col-secondary);

        }
        .notify-mark-dot{
            width: 12px;
            height: 12px;
            .dot{
                background-color: var(--col-btn-primary);
                border-radius: 100%;
                width: 12px;
                height: 12px;
            }
        }
    }
}


@media screen and (max-width: 1199.8px) {
    .tab-btn{
        padding: 0 5px !important;
    }
    .filter-btn{
        margin-left: 10px !important;
    }
}