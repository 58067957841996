.title-btn{
    button{
        font-size: var(--font-18);
        font-weight: var(--f-w-500);
        line-height: 24px;
        letter-spacing: 0.01em;
        color: var(--col-primary);
    }
}
.ssc-newsfeed-box{
    .name-box-set{
        background-color: var(--col-EFFAFF);
        border-radius: 8px;
        .title{
            margin-bottom: 8px;
            color: var(--col-body);
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 19.5px;
            letter-spacing: 0.01em;
        }
        .text{
            font-size: var(--font-16);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0.01em;
        }
    }
    .newsfeed-top-btn button{
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
        height: 36px;
    }
}
.empty-set{
    p{
        font-size: var(--font-14);
        font-weight: var(--f-w-400);
        line-height: 20px;
        margin-top: 43px;
        margin-bottom: 43px;
        color: var(--col-body);
    }
    .ssc-primary-green-btn{
        height: 36px;
        border-radius: 4px;
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
    }
}
.add-newsfeed-text{
    padding: 12px 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
    border: 1px solid var(--col-label);
    border-radius: 8px;
    &::placeholder{
        color: var(--col-body);
    }
    &:focus{
        border: 1px solid var(--col-btn-primary);
        box-shadow: none;
    }
    &:focus-visible{
        outline: none;
    }
}
.new-table-responsiveness{
    margin-top: 12.5px;
    .ssc-table-area{
        height: calc(100vh - 257px) !important;
    }
}
.new-table-responsiveness-reg{
    .ssc-table-area{
        height: calc(100vh - 160px) !important;
    }
}
.new-table-responsiveness-scorecard{
    margin-top: 13px;
    .ssc-table-area{
        height: calc(100vh - 270px) !important;
        .tab-pill-scorecard{
            height: 17px;
            padding: 0 8px;
            background-color: var(--col-E4FBFA);
            border-radius: 1000px;
            border: 1px solid var(--col-br-dark);
            line-height: 1;
        }
    }
}