.ssc-header{
    color: var(--col-secondary);
    padding: 16px 32px;
    border-bottom: 1px solid var(--col-br-dark);
    height: 72px;
    .logo{
        padding: 4px 0px;
    }
    .ssc-header-right{
        gap: 24px;
        .ssc-bell-btn{
            background-color: var(--col-white);
            .notification-num{
                color: var(--col-white);
                background-color: var(--col-error);
                top: -3px;
                right: 0px;
                border-radius: 100%;
                font-size: 10px;
                font-weight: 500;
                width: 15px;
                height: 15px;
            }
        }
        .ssc-profile-dropdown{
            .ssc-header-dropdown{
                border: none;
                gap: 10.5px;
                background-color: var(--col-white);
                .img-area{
                    border-radius: 100%;
                    background-color: var(--col-label);
                    width: 35px;
                    height: 35px;
                }
                .dropdown-arrow{
                    width: 16px;
                    height: 16px;
                }
            }
            #MenuSidebarMobileView {
                position: fixed;
                right: 30px;
                top: 60px;
                border: 1px solid var(--col-br-dark);
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
                z-index: 100;
                height: 265px;
                min-width: 249px;
                transform: translateX(330px);
                overflow: hidden;
                transition: all ease-in-out 0.3s;
                border-radius: 18px;
                &.show {
                    transform: translateX(0px);
                }
                .profile-img{
                    border-radius: 100%;
                    width: 56px;
                    height: 56px;
                    border: 1px solid var(--col-br-dark);
                    margin-bottom: 19px;
                }
                .user-name{
                    font-weight: var(--f-w-600);
                    font-size: var(font-16);
                    margin-bottom: 48px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                }
                .line-separtor{
                    background-color: var(--col-br-dark);
                    height: 1px;
                }
                .link-div{
                    font-weight: var(--f-w-400);
                    font-size: var(--font-14);
                    line-height: 24px;
                    text-decoration: none;
                    color: var(--col-secondary);
                }
            }
        }
    }
}
@media screen and (max-width: 991.8px) {
    .ssc-header{
        padding: 16px 18px;
    }
}