.customer-dashboard-view{
    overflow: hidden;
    height: calc(100vh - 117px);
    .main-content, .main-content-inner{
        margin: 0 -16px;
    }
    .col-9.custom-col-set, .col-3.custom-col-set, .col-2.custom-col-set, .col-10.custom-col-set, .col-6.custom-col-set{
        padding: 0 16px;
    }
    .mb-32{
        margin-bottom: 32px;
    }
    .top-left{
        border: 1px solid var(--col-br-dark);
        padding: 16px 16px 23px 16px;
         border-radius: 4px;
        img{
            border-radius: 100%;
        }
        h5{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--col-body);
        }
        h4{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--col-primary);
            width: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            height: 24px;
        }
    }
    .top-right{
        background-color: rgba(243, 252, 255, 0.6);
        border: 1px solid var(--col-br-dark);
        padding: 24px 32px;
        border-radius: 8px;
        h3{
            font-size: var(--font-24);
            font-weight: var(--f-w-500);
            line-height: 32px;
            letter-spacing: 0.01em;
            color: var(--col-primary);
        }
        .row{
            margin: 0 -8px;
            .col-lg-4.col-6{
                padding: 0 8px;
            }
        }
        .label-box{
            color: var(--col-primary);
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 1;
            letter-spacing: 0em;
            padding: 0px 16px;
            height: 32px;
            border-radius: 4px;
            span{
                margin-bottom: 1px;
            }
            .dot{
                border-radius: 100%;
                height: 10px;
                width: 10px;
                min-width: 10px;
            }
            .red-dot{
                background-color: var(--col-error);
            }
            .yellow-dot{
                background-color: var(--col-warning);
            }
            .green-dot{
                background-color: var(--col-009262);
            }
            img{
                margin-bottom: 1px;
            }
        }
        .red-label{
            border: 1px solid var(--col-F0857D);
            background-color: var(--col-FFF1F0);
            transition: all ease-in-out 0.3s;
            &:hover{
                background-color: var(--col-hov-f7d2cf);
            }
        }
        .yellow-label{
            border: 1px solid var(--col-F0857D);
            background-color: var(--col-FDF5ED);
            transition: all ease-in-out 0.3s;
            &:hover{
                background-color: var(--col-hov-f9d9ba);
            }
        }
        .green-label{
            border: 1px solid var(--col-84DFC1);
            background-color: var(--col-E4FBFA);
            transition: all ease-in-out 0.3s;
            &:hover{
                background-color: var(--col-hov-c5fdfa);
            }
        }  
    }
    .bottom-left, .bottom-right{
        .card-container{
            gap: 24px;
            border: 1px solid var(--col-br-dark);
            border-radius: 8px;
            .card-title-area{
                h5{
                    font-size: var(--font-16);
                    font-weight: var(--f-w-500);
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: var(--col-primary);
                    margin-bottom: 0px;
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }
            .content-set-area{
                gap:8px;
                height: calc(100vh - 503px);
                overflow: auto;            
                .content-set{
                    height: 50px;
                    margin-bottom: 10px;
                    h5{
                        font-size: var(--font-14);
                        font-weight: var(--f-w-500);
                        line-height: 1;
                        letter-spacing: 0em;
                        color: var(--col-primary);
                    }
                    span{
                        font-size: var(--font-13);
                        font-weight: var(--f-w-400);
                        line-height: 1;
                        letter-spacing: 0.01em;
                        color: var(--col-body);
                    }
                }
            }
        }
    }
    .bottom-right{
        .content-set{
            border-bottom: 1px solid var(--col-br-dark);
            padding: 8px 0px;
            &:first-child{
                padding-top: 0px !important;
            }
            &:last-child{
                border-bottom: none;
            }
            .icon-spacing{
                width: 32px;
                height: 32px;
                transition: all 0.3s ease-in-out;
                position: relative;
                border-radius: 4px;
                cursor: pointer;
               .cross-icon{
                    display: none;
                    position: absolute;
               }
               &:hover{
                    background-color: var(--col-E4FBFA);
                    .cross-icon{
                        display: block;
                        top: 0px;
                        right: 0px;
                    }
               }
            }
        }
        p{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 18px;
            letter-spacing: 0em;
            color: var(--col-secondary);
        }
        .without-doc{
            gap: 44px;
            margin-top: 44px;
            p{
                font-size: var(--font-14);
                font-weight: var(--f-w-400);
                line-height: 20px;
                letter-spacing: 0em;
                color: var(--col-body);
            }
        }
        .col-dot{
            border-radius: 100%;
            height: 10px;
            width: 10px;
            margin-right: 16px;
            min-width: 10px;
        }
        .red-dot{
            background-color: var(--col-error);
        }
        .green-dot{
            background-color: var(--col-009262);
        }
        .blue-dot{
            background-color: var(--col-1145C8);
        }
        .yellow-dot{
            background-color: var(--col-warning);
        }
    }

    //activity-bar
    .ssc-activity-bar{
        gap: 32px;
        .activity-main{
            border-radius: 8px;
            border: 1px solid var(--col-br-dark);
            padding: 24px 12px;
            h5{
                font-size: var(--font-16);
                font-size: var(--f-w-500);
                line-height: 24px;
                letter-spacing: 0.01em;
                color: var(--col-primary);
                margin-left: 12px;
                margin-right: 12px;
            }
            .activity-list{
                padding: 0 12px;
                overflow-x: hidden;
                overflow-y: auto;
                height: calc(100vh - 300px);
                .list-container{
                    gap: 24px;
                    .list{
                        gap: 16px;
                    }
                }
            }
        }
        .ssc-secondary-white-btn{
            font-size: var(--font-16) !important;
            margin-bottom: 8px;
        }
    }
}
    

@media screen and (max-width: 1199.8px) {
    .main-content, .main-content-inner{
        margin: 0 -12px !important;
    }
    .col-9.custom-col-set, .col-3.custom-col-set, .col-2.custom-col-set, .col-10.custom-col-set, .col-6.custom-col-set{
        padding: 0 12px !important;
    }
    .mb-32{
        margin-bottom: 24px !important;
    }
    .top-right{
        padding: 12px 16px !important;
    }
    .bottom-right{
        .col-dot{
            margin-right: 10px !important;
        }
    }
    .bottom-left, .bottom-right{
        .content-set-area{
            height: calc(100vh - 518px) !important;
        }
    }
    .activity-main{
        padding: 24px 10px !important;
        h5{
            margin: 0 10px 20px 10px !important;
        }
        .activity-list{
            padding: 0 10px !important;
            .list{
                gap: 12px !important;
            }
        }
    }
}
@media screen and (max-width: 991.8px) {
    .bottom-left, .bottom-right{
        .content-set-area{
            height: calc(100vh - 503px) !important;
        }
    }
}