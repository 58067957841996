.left-content-box{
    border: 1px solid var(--col-br-dark);
    padding: 16px;
    border-radius: 16px;
    .scroll-set{
        padding: 16px;
        overflow-y: auto;
        gap: 32px;
        overflow-x: hidden;
        max-height: calc(100vh - 265px);
    }
    .top-labels{
        column-gap: 56px;
        row-gap: 16px;
        flex-wrap: wrap;
        .label-set{
            p{
                font-size: var(--font-14);
                font-weight: var(--f-w-400);
                line-height: 24px;
                color: var(--col-secondary);
                margin-bottom: 16px;
            }
            .name{
                font-size: var(--font-14);
                font-weight: var(--f-w-500);
                line-height: 1;
                color: var(--col-primary);
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
    }
    .alert-box-text{
        padding: 8px;
        border-radius: 4px;
        gap: 8px;
        .title{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 1;
        }
        .sub-title{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 1;
            color: var(--col-primary);
        }
    }
    .alert-box-text.red{
        background-color: var(--col-FFF1F0);
        .title{
            color: var(--col-FF004D);
        }
    }
    .notes-section{
        h6{
            font-size: var(--font-14);
            font-weight: var(--f-w-400);
            line-height: 24px;
            color: var(--col-secondary);
        }
        p{
            font-size: var(--font-14);
            font-weight: var(--f-w-400);
            line-height: 21px;
            color: var(--col-primary);
            margin: 11px 0;
        }
    }
    .doc-submit{
        .doc-upload-add{
            .doc-hov{
                margin-top: 0px !important;
            }
            .add-btn-area{
                max-height: 40px;
                max-width: 40px;
                height: 40px;
                width: 40px;
                border-radius: 100%;
            }
        }
        h6{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 1;
            color: var(--col-primary);
        }
    }
    .upload-btn{
        padding: 10px 16px;
        border-radius: 4px;
        height: 36px;
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
    }
}
.right-content-box{
    border: 1px solid var(--col-br-dark);
    padding: 16px;
    border-radius: 16px;
    .scroll-right-div{
        padding: 16px;
        max-height: calc(100vh - 225px);
        overflow-y: auto;
        overflow-x: hidden;
        h6{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 24px;
            color: var(--col-primary);
        }
    }
}
@media screen and (max-width: 991.8px) {
    .left-content-box, .right-content-box{
        padding: 8px;
    }
}