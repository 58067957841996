.ssc-sliderbar{
    padding: 24px 16px;
    border-right: 1px solid var(--col-br-dark);
    width: 242px !important;
    max-width: 242px !important;
    &.collapse:not(.show){
        display: block;
        flex-basis: auto;
    }
    .link-area{
        list-style-type: none;
        .links-set{
            .link-line{
                gap: 8px;
                text-decoration: none;
                color: var(--col-body);
                padding: 12px 16px;
                margin-bottom: 8px;
                border-radius: 4px;
                background-color: var(--col-white);
                overflow: hidden;
                span{
                    word-break: keep-all;
                }
                svg{
                    min-width: 16px;
                    min-height: 16px;
                }
                &.active, &:hover{
                    color: var(--col-primary);
                    background-color: var(--col-E4FBFA);
                    svg path,rect,mask{
                        stroke: var(--col-primary); 
                    }
                }
            }
        }
    }
    
}
@media screen and (max-width: 991.8px){
    .ssc-sliderbar{
        position: fixed;
        left: 0px;
        top: 72px;
        z-index: 100;
        height: calc(100vh - 72px) !important;
        transition: all ease-in-out 0.35s !important;
        width: 74px !important;
        max-width: 74px !important;
        .link-line span{
            width: 0px;
            max-width: 0px;
            visibility: hidden;
            white-space: nowrap;
            transition: all ease-in-out 0.35s !important;
        }
        &.show{
            width: 242px !important;
            max-width: 242px !important;
            background-color: var(--col-white);
            .link-line span{
                width: 100%;
                max-width: 100%;
                visibility: visible;
                transition: all ease-in-out 0.35s !important;
            }
        }
    }
    
}
.ssc-slider-btn{
    top: 10px;
    left: 63px;
    border-radius: 100%;
    border: 1px solid var(--col-br-dark);
    max-width: 24px !important;
    width: 24px !important;
    max-height: 30px;
    z-index: 110;
    transition: all ease-in-out 0.35s !important;
    img{
        transform: rotate(180deg);
        margin-left: 2px;
    }
    &.open{
        left: 233px;
        img{
            transform: rotate(0deg);
            margin-left: -3px;
        }
    }
}