#MenuSidebarMobileView3{
    padding: 24px;
    border-radius: 16px;
    position: fixed;
    right: 30px;
    bottom: 0px;
    border: 1px solid var(--col-br-dark);
    z-index: 100;
    height: 605px !important;
    min-width: 360px;
    max-width: 360px;
    transform: translateX(390px);
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    &.show {
        transform: translateX(0px);
    }
    h2{
        font-size: var(--font-24);
        font-weight: var(--f-w-500);
        line-height: 32px;
        letter-spacing: 0.01em;
        color: var(--col-primary);
    }
    .rating{
        p{
            font-size: var(--font-14);
            font-weight: var(--f-w-400);
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--col-body);
        }
        .ssc-rating-set{
            gap: 8px;
            margin-bottom: 35.5px;
        }
    }
    .ssc-enchanced{
        margin-bottom: 32px;
        h3{
            font-size: var(--font-18);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0.01em;
        }
        .pills-set{
            gap: 10px;
            .pills{
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 20px;
                letter-spacing: 0.005em;
                color: var(--col-secondary);
                height: 41px;
                padding: 0 16px;
                border-radius: 8px;
                background-color: var(--col-E4FBFA);
                transition: all ease-in-out 0.3s;
                cursor: pointer;
                &:hover{
                    background-color: var(--col-5DEADE);
                }
                &:active{
                    transform: scale(1.05);
                }
                &.active{
                    background-color: var(--col-5DEADE);
                }
            }
        }
    }
    .ssc-comments{
        label{
            margin-bottom: 8px;
        }
        textarea{
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 20px;
            letter-spacing: 0.005em;
            border: 1px solid var(--col-br-dark);
            border-radius: 8px;
            padding: 12px;
            width: 100%;
            height: 123px;
            resize: none;
            &::placeholder{
                color: var(--col-C5D2E7);
            }
            &:focus-visible{
                outline: none;
            }
        }
    }
}