body{
    color: var(--col-primary) !important;
    background-color: var(--col-white) !important;
    font-weight: var(--f-w-400) !important;
    font-size: var(--font-14) !important;
    font-family: var(--font-family) !important;
}
main{
    height: calc(100vh - 72px);
    overflow: hidden;
}
.ssc-main-content{
    padding: 29px 30px 19px 30px !important;
}
.main-header{
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 0px;
}
.ssc-header-title{
    height: 36px;
}
.ssc-secondary-white-btn{
    padding: 10px 16px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--col-btn-primary);
    border-radius: 4px;
    background-color: var(--col-white);
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
    p{
        margin-bottom: 0px;
    }
    &:hover{
        background-color: var(--col-label);
        border: 1px solid var(--col-btn-primary) !important;
        color: var(--col-btn-primary) !important;
    }
}
.ssc-primary-green-btn{
    background-color: var(--col-btn-primary);
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-18);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    border: none;
    border-radius: 8px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
    &:hover{
        background-color: var(--col-btn-primary-hover);
    }
    &:disabled{
        background-color: var(--col-label);
        cursor: not-allowed;
    }
}
.ssc-card-btn{
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
    gap: 8px;
    border: none;
    background-color: var(--col-white);
    display: flex;
    align-items: center;
    &:hover{
        color: var(--col-btn-primary-hover);
    }
}
.ssc-card-btn2{
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
    gap: 8px;
    border: none;
    background-color: var(--col-white);
    display: flex;
    align-items: center;
    &:hover{
        color: var(--col-btn-primary-hover);
    }
}
.ssc-search-box{
    .search-box{
        position: relative;
        width: 100%;
        padding: 8px;
        border: 1px solid var(--col-br-dark);
        border-radius:4px;
        gap: 8px;
        background-color: var(--col-white);
        .search-area{
            color: var(--col-body);
            line-height: 20px;
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            letter-spacing: 0.005em;
            &::placeholder{
                font-size: var(--font-13);
                font-weight: var(--f-w-400);
                line-height: 20px;
                letter-spacing: 0.005em;
                color: var(--col-body);
            }
            &:focus-visible, &:focus{
                outline: none !important;
            }
        }
    }
    .ssc-filters{
        gap: 8px;
        select{
            height: 36px;
            border: 1px solid var(--col-E9E9E9);
            padding: 0px 12px;
            border-radius: 4px;
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--col-secondary);
            background-color: var(--col-white);
        }
    }
}
.ssc-table-pagination-bar{
    height: 24px;
    max-height: 24px;
    color: var(--col-body);
    .left-number-set{
        font-size: var(--font-13);
        font-weight: var(--f-w-400);
        line-height: 20px;
        letter-spacing: 0.005em;
    }
    .right-number-set{
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        .number-set{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 20px;
            letter-spacing: 0.005em;
        }
    }
}
.dot-set{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
    .col-dot{
        border-radius: 100%;
        height: 10px;
        width: 10px;
        min-width: 10px;
    }
    .red-dot{
        background-color: var(--col-error);
    }
    .green-dot{
        background-color: var(--col-009262);
    }
    .blue-dot{
        background-color: var(--col-1145C8);
    }
    .yellow-dot{
        background-color: var(--col-warning);
    }
}
.status-set{
    display: flex;
    align-items: center;
    .status{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size:var(--font-12);
        font-weight: var(--f-w-400);
        line-height: 18px;
        letter-spacing: 0.01em;
        color: var(--col-primary);
        border-radius: 1000px;
        padding: 0px 8px;
        height: 20px;
    }
    .rej-status{
        border: 1px solid var(--col-E02B1D);
        background-color: var(--col-FFF1F0);
    }
    .req-status{
        border: 1px solid var(--col-5DEADE);
        background-color: var(--col-E4FBFA);
    }
    .pending-status{
        border: 1px solid var(--col-FFB240);
        background-color: var(--col-FFF8EC);
    }
    .approve-status{
        border: 1px solid var(--col-32C997);
        background-color: var(--col-F1FBF8);
    }
}
.custom-date-box{
    border: 1px solid var(--col-E9E9E9);
    color: var(--col-secondary);
    height: 36px;
    padding: 0 12px;
    gap: 4px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    cursor: pointer;
}
.select-box-new{
    border: 1px solid var(--col-E9E9E9);
    color: var(--col-secondary);
    height: 32px;
    padding: 0 12px;
    margin-right: 47px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    background-color: var(--col-white) !important;
}
.mt-32{
    margin-top: 32px;
}
.mb-32{
    margin-bottom: 32px;
}
.modal.fade{
    background-color: rgba(0, 0, 0, 0.175) !important;
}
.btn.ssc-secondary-white-btn, .ssc-secondary-white-btn.btn:first-child:active{
    color: var(--col-btn-primary) !important;
    background-color: var(--col-white) !important;
    border-color: var(--col-btn-primary) !important;
}
.ssc-secondary-white-btn.btn:hover {
    background-color: var(--col-label) !important;
    border: 1px solid var(--col-btn-primary) !important;
    color: var(--col-btn-primary) !important;
  }
select:focus-visible {
    outline: none;
}
.ssc-primary-green-btn.unique-btn {
    border-radius: 4px;
    height: 36px;
    width: 50px;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
}
.hov-effect-boardcast{
    padding: 5px;
    width: 40px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: var(--col-E4FBFA);
    }
}
.dot-sentence{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.error-page-box1{
    h2{
        font-size: var(--font-92-81);
        font-weight: var(--f-w-600);
        line-height: 1;
        letter-spacing: 0.0025em;
        color: var(--col-primary);
        margin:48px 0;
    }
    button{
        border-radius: 8px;
    }
}
.error-page-box2{
    h2{
        font-size: var(--font-92-81);
        font-weight: var(--f-w-600);
        line-height: 1;
        letter-spacing: 0.0025em;
        color: var(--col-primary);
        margin-top: 32px;
        margin-bottom: 25px;
    }
    p{
        margin-bottom: 48px;
        font-size: var(--font-16);
        font-weight: var(--f-w-500);
        line-height: 1;
        letter-spacing: 0.01em;
        color: var(--col-secondary);
    }
    button{
        border-radius: 8px;
    }
}


// custom radio buttons css
.priority-box-set{
    gap: 16px;
    .box-container-set{
        border: 1px solid var(--col-br-dark);
        border-radius: 4px;
        height: 40px;
        padding: 0 16px;
        &.active{
            border-color: var(--col-btn-primary);
        }
        .container-custom{
            display: block;
            position: relative;
            padding-left: 35px;
            cursor: pointer;
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 24px;
            color: var(--col-secondary);
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input{
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }
            .checkmark{
                position: absolute;
                top: 0;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: var(--col-white);
                border-radius: 50%;
                border: 2px solid var(--col-body);
            }
        }
        
        /* When the radio button is checked, add a blue background */
        .container-custom input:checked ~ .checkmark {
            background-color: var(--col-white);
            border: 2px solid var(--col-btn-primary);
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        .container-custom input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .container-custom .checkmark:after {
            left: 50%;
            transform: translate(-50%, 13.5%);
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: var(--col-btn-primary);
        }
    }
}


@media screen and (min-width: 991.8px){
    .ssc-main-content{
        width: calc(100vw - 242px) !important;
    }
}
@media screen and (max-width: 991.8px) {
    .ssc-main-content{
        padding: 29px 16px 19px 16px !important;
        flex-grow: 1;
        width: auto !important;
        margin-left: 74px;
        flex-shrink: inherit !important;
    }
    #MenuSidebarMobileView4.show, #MenuSidebarMobileView.show, #MenuSidebarMobileView3.show {
        transform: translateX(14px) !important;
    }
}

/* height and width */
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
    background-color: var(--col-br-dark);
    width: 2px;
    border-radius: 6px;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--col-btn-primary); 
    border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--col-btn-primary-hover);
}


// custom-dropdown style css
.wrapper {
    width: 100%;
    &.active {
        .content {
            display: block;
            border: 1px solid var(--col-label);
            border-top: transparent;
        }
    }
    .select-btn {
        background-color: #fff;
        height: 48px;
        padding: 0 16px;
        border-radius: 8px;
        border: 1px solid var(--col-label);
        font-size: var(--font-13);
        font-weight: var(--f-w-500);
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: var(--col-C5D2E7);
    }
    .content {
        background-color: #fff;
        margin-top: -11px;
        padding: 16px;
        border-radius: 0 0 8px 8px;
        display: none;
        z-index: 10;
        .search {
            position: relative;
            input {
                height: 48px;
                width: 100%;
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 20px;
                letter-spacing: 0.005em;
                text-align: left;
                outline: none;
                padding: 16px 16px 16px 38px;
                border:1px solid var(--col-label);
                border-radius: 8px;
                color: var(--col-primary);
                &::placeholder{
                    color: var(--col-C5D2E7);
                }
                &:focus-visible{
                    outline: none;
                }
            }
            img, .search-icon-svg{
                position: absolute;
                left: 15px;
                top: 15px;
            }
        }
        .options {
            margin-top: 16px;
            padding: 0;
            max-height: 190px;
            overflow-y: auto;
            li {
                height: 25px;
                cursor: pointer;
                padding: 8px;
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 20px;
                letter-spacing: 0.005em;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                &:hover {
                    background-color: var(--col-E4FBFA);
                }
            }
        }
    }
}