*{
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

@import "variables";
@import "common";
@import "admin-login";
@import "admin-dashboard";
@import "header";
@import "sidebar";
@import "table";
@import "profile";
@import "customer-dasboard";
@import "feedback";
@import "notification-dropdown";
@import "notification-page";
@import "doc-request-box";
@import "newsfeed.scss";
@import "upload-document";
@import "scorecard";