.title-btn{
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    gap: 10px;
    margin-bottom: 32px;
}
.profile-box{
    .profile-img{
        border: 1px solid var(--col-br-dark);
        border-radius: 16px;
        padding: 24px;
        img{
            width: 80px;
            height: 80px;
            border-radius: 100%;
            margin-bottom: 24px;
        }
        .img-edit{
            position: absolute;
            bottom: 21px;
            right: 0px;
            img{
                width: 24px;
                height: 24px;
                margin-bottom: 0px;
                cursor: pointer;
            }
        }
        h4{
            font-size: var(--font-16);
            font-weight: var(--f-w-500);
            line-height: 1;
            letter-spacing: 0.01em;
            color: var(--col-secondary);
        }
        p{
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 1;
            letter-spacing: 0.005em;
            color: var(--col-secondary);
        }
    }
}
.tabs-set{
    .tabs-row{
        border-bottom: 1px solid var(--col-br-dark);
        width: 100%;
        .link-profile{
            color: var(--col-primary);
            height: 42px;
            padding: 0 16px;
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 1;
            letter-spacing: 0em;
            position: relative;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &.active{
                color: var(--col-btn-primary);
            }
            &.active::before{
                content: "";
                position: absolute;
                width: 100%;
                bottom: -1px;
                left: 0px;
                height: 1px;
                background-color: var(--col-btn-primary);
            }
            &:hover{
                color: var(--col-btn-primary);
            }
        }
    }
    .form-area{
        height: calc(100vh - 240px);
        overflow-y: auto;
        overflow-x: hidden;
        .pwd-sub-para{
            font-size: var(--font-12);
            font-weight: var(--f-w-400);
            line-height: 18px;
            letter-spacing: 0.01em;
            margin-bottom: 24px;
            color: var(--col-body);
        }
        .eye-icon2{
            top: 13px;
            right: 16px;
            background-color: transparent;
        }
        .form-title{
            font-size: var(--font-14);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0em;
        }
        .input-set{
            label{
                font-size: var(--font-13);
                font-weight: var(--f-w-400);
                line-height: 1;
                letter-spacing: 0.01em;
                margin-bottom: 8px;
                color: var(--col-secondary);
            }
            .form-control{
                padding: 0px 16px;
                height: 48px;
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 20px;
                letter-spacing: 0.005em;
                color: var(--col-primary);
                &:focus{
                    box-shadow: none;
                    border: 1px solid var(--col-btn-primary);
                }
                &::placeholder{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-500);
                    line-height: 19.5px;
                    letter-spacing: 0.005em;
                    color: var(--col-C5D2E7);
                }
            }
        }
        .form-button{
            background-color: var(--col-btn-primary);
            border-radius: 4px;
            height: 40px;
            font-size: var(--font-16);
            font-weight: var(--f-w-600);
            line-height: 24px;
            letter-spacing: 0.01em;
            padding: 0 16px;
            color: var(--col-white);
            transition: all 0.3s ease-in-out;
            &:hover{
                background-color: var(--col-btn-primary-hover);
            }
            &.disabled{
                background-color: var(--col-label);
                cursor: default;
            }
        }
    }
}
@media screen and (max-width: 991.8px){
    .profile-box{
        padding: 24px;
    }
    .profile-img{
        padding: 16px;
    }
}
@media screen and (max-width: 767.8px){
    .profile-box{
        padding: 16px;
    }
}