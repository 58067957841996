:root {
    --col-primary: #254960;
    --col-secondary: #3F647D;
    --col-body: #738DA6;
    --col-label: #E2F1F7;
    --col-br-light: #E9F0FB;
    --col-br-dark: #E2F1F7;
    --col-white: #ffffff;
    --col-E4FBFA: #E4FBFA;
    --col-tra: transparent;
    --col-table-head: #F4FAFD;
    --col-4B5564: #4B5564;
    --col-C5D2E7: #C5D2E7;
    --col-E9E9E9: #E9E9E9;
    --col-E02B1D: #E02B1D;
    --col-FFF1F0: #FFF1F0;
    --col-5DEADE: #5DEADE;
    --col-FFB240: #FFB240;
    --col-FFF8EC: #FFF8EC;
    --col-32C997: #32C997;
    --col-F1FBF8: #F1FBF8;
    --col-009262: #009262;
    --col-1145C8: #1145C8;

    --col-F0857D: #F0857D;
    --col-FF004D: #FF004D;
    --col-FFC670: #FFC670;
    --col-FDF5ED: #FDF5ED;
    --col-84DFC1: #84DFC1;
    --col-FF5A4F: #FF5A4F;
    --col-FFCCDB: #FFCCDB;
    --col-EFFAFF: #EFFAFF;
    --col-2D343F: #2D343F;

    --col-hov-c5fdfa: #c5fdfa;
    --col-hov-f9d9ba: #f9d9ba;
    --col-hov-f7d2cf: #f7d2cf;

    // button's colors 
    --col-btn-primary: #17B1A4;
    --col-btn-primary-hover: #128C82;

    // alert's colors
    --col-warning: #FFA826;
    --col-error: #E01507;
    --col-success: #1B6E53;

    //font
    --font-family: "Rubik", sans-serif;
    --font-10: 10px;
    --font-11: 11px;
    --f-w-400: 400;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --f-w-600: 600;
    --font-16: 16px;
    --f-w-500: 500;
    --font-18: 18px;
    --font-24: 24px;
    --font-32: 32px;
    --font-92-81: 92.81px;
}