.title-btn{
    .ssc-primary-green-btn{
        height: 36px;
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
        border-radius: 4px;
        color: var(--col-white);
    }
}
.ssc-scorecard-box{
    .name-box-set{
        background-color: var(--col-EFFAFF);
        border-radius: 8px;
        .title{
            margin-bottom: 8px;
            color: var(--col-body);
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 19.5px;
            letter-spacing: 0.01em;
        }
        .dropdown-name{
            height: 48px;
            width: 70%;
            padding: 0 16px;
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 19.5px;
            letter-spacing: 0.005em;
            background-color: var(--col-white);
            color: var(--col-primary);
            border: 1px solid var(--col-br-dark);
            border-radius: 8px;
            appearance: none;
            background-image: url("../img/icons/arrow-dropdown.svg");
            background-repeat: no-repeat;
            background-position: right 1rem top 50%;
            background-size: 0.65rem auto;
        }
    }
    .upper-user-text{
        font-size: var(--font-13);
        font-weight: var(--f-w-500);
        line-height: 19.5px;
        letter-spacing: 0.005em;
        color: var(--col-2D343F);
        margin-right: 14px;
    }
    .ssc-search-box{
        .search-box{
            min-width: 343px;
            height: 48px;
            @media screen and (max-width: 991.8px){
                min-width: 250px;
            }
            img{
                width: 16px;
                height: 16px;
                top: -1px;
                position: relative;
            }
            ::placeholder{
                color: var(--col-C5D2E7);
            }
        }
    }
    .grade-dropdown-custom{
        .custom-btn{
            height: 48px;
            width: 107px;
            padding: 0 16px;
            gap: 16px;
            font-size: var(--font-13);
            font-weight: var(--f-w-500);
            line-height: 19.5px;
            letter-spacing: 0.005em;
            color: var(--col-secondary);
            border: 1px solid var(--col-br-light);
            border-radius: 4px;
            &::after{
                display: none;
            }
        }
        .custom-drop-content{
            transform: translate3d(0px, 40px, 0px) !important;
            min-width: 107px;
            width: 107px;
            max-width: 107px;
            border-radius: 0px 0px 4px 4px;
            border: 1px solid var(--col-br-light);
            border-top: 1px solid transparent;
            padding: 5px 16px;
            .dropdown-item{
                gap: 8px;
                background-color: var(--col-white);
                padding: 0px 8px;
                border-radius: 4px;
                margin-bottom: 8px;
                .dot-circle{
                    margin-top: 2px;
                    width: 10px;
                    height: 10px;
                    max-width: 10px;
                    max-height: 10px;
                    min-width: 10px;
                    min-height: 10px;
                    border-radius: 100%;
                    &.red{
                        background-color: var(--col-error);
                    }
                    &.yellow{
                        background-color: var(--col-warning);
                    }
                    &.green{
                        background-color: var(--col-009262);
                    }
                }
                &:hover{
                    background-color: var(--col-br-dark);
                }
                .text-txt span{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-500);
                    line-height: 19.5px;
                    letter-spacing: 0.005em;
                    color: var(--col-4B5564);
                }
            }
        }
    }
}
.ssc-scorecard-form{
    label{
        font-size: var(--font-13);
        font-weight: var(--f-w-400);
        line-height: 1;
        letter-spacing: 0.01em;
        color: var(--col-secondary);
        margin-bottom: 8px;
    }
    textarea::placeholder{
        color: var(--col-C5D2E7);
    }
}
