.ssc-table-area{
    height: calc(100vh - 295px);
    overflow: auto;
    position: relative;
    table{
        background-color:var(--col-white);
        .grade-dot{
            width: 65px;
        }
        thead{
            position: sticky;
            top: -1px;
            left: 0;
            z-index: 90;
            tr th{
                padding: 16px;
                color: var(--col-primary);
                font-size: var(--font-14);
                font-weight: var(--f-w-500);
                line-height: 17px;
                letter-spacing: 0em;
                vertical-align: middle;
                background-color: var(--col-table-head);
                &.option-td{
                    width: 84px;
                    text-align: center;
                }
                &.option-td-go-btn{
                    width: 54px;
                    text-align: center;
                }
                &.tab-td{
                    width:410px;
                    @media screen and (max-width: 1300px){
                        width: 360px;
                    }
                    @media screen and (max-width: 1200px){
                        width: 300px;
                    }
                    @media screen and (max-width: 991px){
                        width: auto;
                    }
                }
                &.csm-name, &.admin-name{
                    @media screen and (min-width: 991px){
                        width: 190px;
                        max-width: 190px;
                    }
                }
                &.csm-id, &.admin-id{
                    @media screen and (min-width: 991px){
                        width: 100px;
                        max-width: 100px;
                    }
                }
                &.csm-phone, &.admin-phone{
                    @media screen and (min-width: 991px){
                        width: 390px;
                        max-width: 390px;
                    }
                }
                &.hire-client{
                    @media screen and (min-width: 700px){
                        width: 300px;
                        max-width: 300px;
                        min-width: 300px;
                    }
                    @media screen and (min-width: 1300px){
                        width: 200px;
                        max-width: 200px;
                        min-width: 200px;
                    }
                    
                }
                &.tpa-area{
                    @media screen and (min-width: 991px){
                        width: 490px;
                        max-width: 490px;
                    }
                    @media screen and (min-width: 1300px){
                        width: 590px;
                        max-width: 590px;
                    }
                    
                }
            }
            tr th:first-child{
                border-radius: 16px 0 0 0;
            }
            tr th:last-child{
                border-radius: 0 16px 0 0;
            }
        }
        tbody{
            tr td{
                padding: 16px;
                font-size: var(--font-13);
                font-weight: var(--f-w-400);
                line-height: 20px;
                color: var(--col-secondary);
                letter-spacing: 0.005em;
                &.email-td{
                    word-break: break-all;
                }
                .table-pill{
                    display: inline;
                    padding: 0 8px;
                    background-color: var(--col-E4FBFA);
                    border-radius: 1000px;
                    border: 1px solid var(--col-br-dark);
                    line-height: 1;
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    letter-spacing: 0.005em;
                    color: var(--col-secondary);
                    text-align: center;
                    box-sizing: border-box;
                }
                .tab-set{
                    gap: 4px;
                    span{
                        padding: 4px 8px;
                        height: 17px;
                        border-radius: 1000px;
                        border: 1px soild var(--col-br-dark);
                        font-size: var(--font-13);
                        font-weight: var(--f-w-400);
                        line-height: 20px;
                        letter-spacing: 0.005em;
                        color: var(--col-secondary);
                        background-color: var(--col-E4FBFA);
                        display: flex;
                        align-items: center;
                    }
                }
                &.option-td{
                    width: 84px;
                    button.bg-white{
                        background-color: transparent !important;
                    }
                    .options-set{
                        gap: 16px;
                    }
                }
                &.option-td-go-btn{
                    width: 54px;
                    text-align: center;
                }
                &.csm-img-td{
                    .img-set{
                        gap:10px;
                        .img-tag{
                            border-radius: 100%;
                        }
                    }
                }
                &.tab-td{
                    width: 410px;
                    @media screen and (max-width: 1300px){
                        width: 360px;
                    }
                    @media screen and (max-width: 1200px){
                        width: 300px;
                    }
                    @media screen and (max-width: 991px){
                        width: auto;
                    }
                }
            }
            tr:nth-child(even) {
                background-color: var(--col-table-head);
            }
            tr:nth-child(odd) {
                background-color:var(--col-white);
            }
        }
    }   
}

.tab-open-area{
    transform: translateX(700px);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 95;
    height: calc(100vh - 295px);
    width: 660px;
    background-color: var(--col-white);
    transition: all ease-in-out 0.3s;
    &.show{
        transform: translateX(0px);
    }
    .sub-title{
        font-size: var(--font-14);
        font-weight: var(--f-w-500);
        line-height: 24px;
        color: var(--col-primary);
        margin-bottom: 24px;
    }
    .close-btn{
        position: absolute;
        left: -15px;
        top: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        box-shadow: 0px 4px 4px 0px #0000000F;
    }
    .content-area{
        overflow-x: hidden;
        height: calc(100vh - 295px);
        overflow-y: auto;
        .delete-icon{
            position: absolute;
            right: 10px;
            top: 52px;
        }
        h6{
            font-size: var(--font-18);
            font-weight: var(--f-w-500);
            line-height: 24px;
            letter-spacing: 0.01em;
            margin-bottom: 16px;
            color: var(--col-primary);
        }
        .left-side{
            .title{
                font-size: var(--font-14);
                font-weight: var(--f-w-500);
                line-height: 24px;
                letter-spacing: 0em;
                color: var(--col-primary);
            }
            .documents-area{
                margin-bottom: 20px;
                .timing{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: var(--col-body);
                }
                .doc{
                    border: 1px solid var(--col-br-dark);
                    border-radius: 8px;
                }
            }
            
        }
        .right-side{
            .detail-area{
                gap: 24px;
                .title{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 20px;
                    letter-spacing: 0.005em;
                    color: var(--col-body);
                    margin-bottom: 8px;
                }
                .text{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 1;
                    letter-spacing: 0.005em;
                    color: var(--col-primary);
                    .dot{
                        border-radius: 100px;
                        width: 10px;
                        height: 10px;
                        background-color: var(--col-error);
                        margin-right: 10px;
                    }
                    img{
                        width: 24px;
                        height: 24px;
                        margin-right: 10px;
                        border-radius: 100px;
                    }
                }
            }
        }
    }
    .list-view-all-btn{
        color: var(--col-btn-primary) !important;
        background-color: transparent !important;
        font-size: var(--font-13);
        font-weight: var(--f-w-400) ;
        line-height: 19.5px;
        letter-spacing: 0.005em ;
        margin-top: 8px;
    }
}
.custom-modal-doc-cato{
    .modal-dialog{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -55%) !important;
        .modal-content{
            border-radius: 16px;
            padding: 32px;
            .head-title-edit{
                font-size: var(--font-18);
                font-weight: var(--f-w-500);
                line-height: 24px;
                letter-spacing: 0.01em;
                color: var(--col-primary);
            }
        }
        .modal-body {
            .input-content{
                    font-size: var(--font-13);
                    font-weight: var(--f-w-400);
                    line-height: 19.5px;
                    letter-spacing: 0.01em;
                    color: var(--col-secondary);
            }
            .input-text{
                border: 1px solid var(#E2F1F7);
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 19.5px;
                letter-spacing: 0.005em;
                color: var(--col-primary);
                height: 48px;
                &:focus{
                    box-shadow: none;
                    border: 1px solid var(--col-btn-primary);
                }

            }
            .form-control.input-area{
                border: 1px solid var(--col-btn-primary);
                padding: 0 12px;
                height: 48px;
                font-size: var(--font-13);
                font-weight: var(--f-w-500);
                line-height: 19.5px;
                letter-spacing: 0.005em;
                color: var(--col-primary);
                margin: 48px 0;
                &:focus{
                    box-shadow: none;
                }
                &::placeholder{
                    color: var(--col-C5D2E7);
                }
            }
            h3.del-title{
                color: var(--col-primary);
                font-size: var(--font-32);
                font-weight: var(--f-w-500);
                line-height: 42px;
                letter-spacing: -0.005em;
                margin-top: 33px;
            }
            p.del-para{
                font-size: var(--font-14);
                font-weight: var(--f-w-400);
                line-height: 24px;
                color: var(--col-4B5564);
                margin-bottom: 48px;
                margin-top: 32px;
            }
        }
        .modal-btn{
            gap: 16px;
            .ssc-primary-green-btn{
                height: 40px;
                border-radius: 4px;
                font-size: var(--font-16);
                font-weight: var(--f-w-600);
                line-height: 24px;
                letter-spacing: 0.01em;
            }
            .ssc-secondary-white-btn{
                height: 40px;
                font-size: var(--font-16);
                font-weight: var(--f-w-600);
                line-height: 24px;
                letter-spacing: 0.01em;
            }
        }
    }
}
.multi-clients{
    gap: 8px;
    span{
        line-height: 1;
    }
}
.customer-area-btn{
    gap: 10px;
    margin-bottom: 50px;
    .w-auto.ssc-secondary-white-btn{
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
        height: 36px;
    }
    .w-auto.ssc-primary-green-btn{
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
        height: 36px;
        border-radius: 4px;
    }
    .btn-del{
        margin-left: 14px;
    }
}
.empty-doc{
    img{
        margin-bottom: 44px;
    }
    span.text-line{
        color: var(--col-body);
        font-size: var(--font-14);
        font-weight: var(--f-w-400);
        line-height: 20px;
    }
}
.filled-doc{
    gap: 24px;
    span{
        color: var(--col-body);
        font-size: var(--font-12);
        font-weight: var(--f-w-400);
        line-height: 16px;
        letter-spacing: 0.01em;
    }
}
.news-feed-btn{
    margin-top: 28px;
    .w-auto.ssc-secondary-white-btn{
        font-size: var(--font-13);
        font-weight: var(--f-w-600);
        line-height: 15.73px;
        letter-spacing: 0.01em;
        height: 36px;
    }
}
.icon1{
    left: 10px;
}
.icon2{
    right: 10px;
}
.doc-hov{
    .icon1, .icon2{
        position: absolute;
        bottom: 10px;
        z-index: 14;
        cursor: pointer;
        display: none;
    }
    .cross-icon{
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 14;
        cursor: pointer;
        display: none;
    }
    margin-top: 24px;
    border-radius: 4px;
    &:hover::after{
        background-color: rgba(0, 0, 0, 0.6);
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 12;
        border-radius: 4px;
    }
    &:hover{
        .icon1{
            display: block !important;
        }
        .icon2{
            display: block !important;
        }
        .cross-icon{
            display: block !important;
        }
    }
}